body {
  margin: 0;
  font-family: "IBM Plex Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.candidate img {
  transition: transform 0.2s, filter 1s ease-in;
}
.candidate:hover img {
  transform: scale(1.05);
  /*filter: contrast(20) saturate(0) sepia(5);*/
}
